<template>
	<div class="locationsequenceheaderFormHeader puiformheader">
		<v-row>
			<v-col cols="3">
				<pui-field :label="$t('header.movementsequenceheader.locationfrom')" :value="getLocationfromValue"></pui-field>
			</v-col>
			<v-col cols="3">
				<pui-field :label="$t('header.movementsequenceheader.locationto')" :value="getLocationtoValue"></pui-field>
			</v-col>
			<v-col cols="3">
				<pui-field :label="$t('header.movementsequenceheader.averagetime')" :value="getAveragetimeValue"></pui-field>
			</v-col>
			<v-col cols="3">
				<pui-field :label="$t('header.movementsequenceheader.pilotage')" :value="getPilotageValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'locationsequenceheader-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'locationsequenceheader'
		};
	},
	computed: {
		getLocationfromValue() {
			return this.model && this.model.locationfromname ? this.model.locationfromname : '-';
		},
		getLocationtoValue() {
			return this.model && this.model.locationtoname ? this.model.locationtoname : '-';
		},
		getAveragetimeValue() {
			return this.model && this.model.averagetime ? this.model.averagetime : '-';
		},
		getPilotageValue() {
			return this.model && this.model.pilotage ? this.$t('common.yes') : this.$t('common.no');
		}
	}
};
</script>
